.img-preview {
  overflow: hidden;
  width: 100%;
  float: left;
  height: 305px;
}

.box {
  display: inline-block;
  width: 100%;
}
