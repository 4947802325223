.ant-steps-item-icon {
  margin-right: 30px;
}

.ant-steps-item-title {
  font-size: 25px;
  padding-bottom: 5px;
  color: black !important;
  font-weight: bold;
}

.ant-upload {
  padding: 0;
}

.ant-upload-select {
  max-width:100%;
  overflow:hidden;
}

.avatar-uploader > .ant-upload {
  border-radius: 50%;
  margin-left: 30px;
  padding: 0;
}

.logo img {
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 110px;
  height: 110px;
  object-fit: cover;
  background-color: #cccccc;
}

.panel {
  padding: 1rem;
  height: 130px;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}

.panel > img {
  object-fit: cover;
  height: 350px;
  width: 100%;
  filter: blur(1px);
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center center;
}

.panel .noImage {
  object-fit: cover;
  border-radius: 3px;
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  display: table;
}

.panel .noImage > div {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  display: table-cell;
}

.panel > style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.child-logo {
  position: absolute;
}

.child-name {
  position: absolute;
  top: 44px;
  left: 200px;
  background-color: #f2f2f2;
  opacity: 0.7;
  border-radius: 5px;
  padding: 5px;
  cursor: default;
}

.child-name .ant-typography {
  margin: 0;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 3px;
}
